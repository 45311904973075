import React, { FC, useEffect } from "react";
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";

export const WTAPaywallPage: FC = () => {
	const { footer, progressBar, paywallPageHandler } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.hide();
		progressBar.hide();
		paywallPageHandler();
	}, []);

	return <div>

	</div>;
};