import { Container, Service, TrackingEventNames, TrackingService } from "../../../symphony";
import { PaywallNavigationPage } from "../paywalls.constants";
import { PaywallsStore } from "../paywalls.store";

@Service()
export class PaywallsTrackingService {
	private trackingSerivce = Container.take('global', TrackingService);
	private store = Container.take('paywalls', PaywallsStore);

	public trackImpression = () => {
		const touchpoint = this.store.currentTouchpoint.value;
		const { key, resolvedVariables } = this.store.paywallData.value;
		const { page, step } = this.store.paywallNavigation.value;
		this.trackingSerivce.tracking$.next({
			eventName: TrackingEventNames.paywallImpression,
			eventParams: {
				touchpoint,
				webPageType: page === PaywallNavigationPage.paywall ? 'PAYWALL' : 'CHECKOUT',
				orderNumber: step,
				resolvedVariables,
				paywallKey: key,
				version: '3-0-0',
				appVersion: '0.0.0'
			}
		});
	};

	public trackClick = (clickType: 'CLOSE' | 'CTA') => {
		const touchpoint = this.store.currentTouchpoint.value;
		const { key, resolvedVariables } = this.store.paywallData.value;
		const { page, step } = this.store.paywallNavigation.value;
		this.trackingSerivce.tracking$.next({
			eventName: TrackingEventNames.paywallClick,
			eventParams: {
				touchpoint,
				clickType,
				webPageType: page === PaywallNavigationPage.paywall ? 'PAYWALL' : 'CHECKOUT',
				orderNumber: step,
				resolvedVariables,
				paywallKey: key,
				version: '3-0-0',
				appVersion: '0.0.0'
			}
		});
	};
}