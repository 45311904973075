import React, { FC, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './MultiChoiceCards.scss';
import { WTAMultiChoiceChangeHandler, WTAPageOption } from "../../../types/webtoapp.types";
import { i18n } from "../../../../../symphony";

type Props = {
	options: WTAPageOption[];
	selectedOptions?: WTAPageOption[];
	onChange: WTAMultiChoiceChangeHandler;
};

export const MultiChoiceCards: FC<Props> = ({ options, selectedOptions, onChange }) => {
	useStyles(s);
	const [selected, setSelected] = useState<WTAPageOption[]>(selectedOptions || []);

	const onSelect = (option: WTAPageOption) => {
		const selectedItems = selected.includes(option)
			? selected.filter(v => v.answerTypeId !== option.answerTypeId)
			: [...selected, option];
		setSelected(selectedItems);
		onChange(selectedItems);
	};

	return <div className="wta-multi-choice">
		{options.map(o => (<div
			className="wta-multi-choice-card"
			key={o.value}
			onClick={() => onSelect(o)}
		>
			<div className="wta-multi-choice-header">
				<img className="wta-multi-choice-image" src={o.iconUrl} />
				<div className={`wta-multi-choice-checkbox ${selected.includes(o) ? 'selected' : ''}`}>
					{selected.includes(o) && <span className="wta-multi-choice-checkmark">
						<div className="wta-multi-choice-checkmark_stem"></div>
						<div className="wta-multi-choice-checkmark_kick"></div>
					</span>}
				</div>
			</div>
			<div className={`wta-multi-choice-footer ${selected.includes(o) ? 'selected' : ''}`}>
				<span className="wta-multi-choice-title">
					{i18n.t(o.label)}
				</span>
			</div>
		</div>))}
	</div>;
};
