import { BehaviorSubject } from 'rxjs';

export interface IError {
    code: number;
    message: string;
    field?: string;
    errorType?: string;
	url?: string;
}

export interface IOldError {
    code: number;
    data: number;
    name: string;
}

export interface IPagingData {
    currentPage: number;
    totalPages: number;
}

export interface IResponse {
    success: boolean;
    errors: IError[] | [];
    error?: IOldError;
    data: unknown;
    paging?: IPagingData;
}

export interface IExperimentsDataResponse extends IResponse {
    data: IExperimentResponse[];
}

export interface IExperimentResponse {
    experimentDate: string;
    experimentId: number;
    experimentName: string;
    flowId: number;
    flowName: string;
    language: string;
    pages: unknown[];
    platform: string;
    version: number;
}

export interface IPaginationParams {
    count?: number;
    page?: number;
}

export enum CodeLanguages {
    CPP = 'cpp',
    C = 'c',
    CSharp = 'cs',
    JAVA = 'java',
    PYTHON = 'py',
    PHP = 'php',
    RUBY = 'rb',
    KOTLIN = 'kt',
    SWIFT = 'swift',
    NODEJS = 'node',
    WEB = 'web',
    JQUERY = 'jquery',
    CSS = 'css',
    HTML = 'html',
    JS = 'js',
    SQL = 'sql',
    RAW = 'raw',
    GO = 'go',
    R = 'r',
    TS = 'ts',
}

export enum ErrorCodes {
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServeError = 500,
    TooManyRequests = 429,
    Locked = 423,
    // used for maintenance
    ServiceUnavailable = 503,
    NotEnoughHearts = 433,
    Conflict = 409,
    OwnershipFailure = 466,
    ExpiredSession = 456,
}

export enum ResponseErrorCodes {
    One = 1,
}

export enum OnboardingPageTypes {
    courseSelection = 'courseSelection',
    auth = 'auth',
    proPromotion = 'proPromotion',
    onboardingSurveyCourses = 'onboardingSurveyCourses',
    categoryPage = 'categoryPage',
}

export enum FileFormat {
    JPG = 'jpg',
    PDF = 'pdf',
}

export interface IApiUrls {
    newExperimentApiHost: string;
    blogApiHost: string;
    wpBlogApiHost: string;
    authApiHost: string;
    learnApiHost: string;
    authenticationApiHost: string;
    codeCoachApiHost: string;
    playgroundApiHost: string;
    trackingApiHost: string;
    paymentApiHost: string;
    onboardingApiHost: string;
    commentsApiHost: string;
    userInfoApiHost: string;
    achievementApiHost: string;
    playgroundUrl: string;
    soloLearnUrl: string;
    cdnApiHost: string;
    cdnApiHostV2: string;
    lookupApiHost: string;
    mailingApiHost: string;
    staticPagesApiHost: string;
    gamificationApiHost: string;
    notificationApiHost: string;
    experimentApiHost: string;
    homeApiHost: string;
    environmentUrl: string;
    socketPath: string;
    codeRepoApiHost: string;
    userSettingsApiHost: string;
    experimentsApiHost: string;
    appsFlyerApiHost: string;
    kudosApiHost: string;
    abTestingApiHost: string;
    iterableSyncApi: string;
    leaderboardApi: string;
    learnEngineApiHost: string;
    xpApiHost: string;
    monolithApiHost: string;
    landingPageApiHost: string;
    certificateApiHost: string;
    streakApiHost: string;
    dynamicTrackingHost: string;
    friendReferralHost: string;
    dynamicOnboardingHost: string;
    trendsApiHost: string;
    subscriptionApiHost: string;
    extTrackingOrchestratorApiHost: string;
    blobApiHost: string;
    aIChatHost: string;
    codeAssistantHost: string;
}

export interface IHostsConfigs {
    paymentApi?: any;
    commentsApi?: any;
    codeCoachApi?: any;
    homeApi?: any;
    profileApi?: any;
    staticPagesApi?: any;
    userSettingsApi?: any;
    onboardingApi?: any;
    achievementApi?: any;
    codeApi?: any;
    gamificationApi?: any;
    authApi?: any;
    kudosApi?: any;
    lookupApi?: any;
    mailingApi?: any;
    abTestingApi?: any;
    leaderboardApi?: any;
    learnEngineApi?: any;
    xpApi?: any;
    landingPageApi: any;
    certificateApi?: any;
    streakApi?: any;
}

export enum PreviousPage {
    Onboarding = 'ONBOARDING',
    Profile = 'MY_COURSE_FROM_PROFILE',
    MyCourse = 'MY_COURSES_PAGE',
    NewCourses = 'COURSE_COLLECTIONS',
}

export enum HeadersAuthorizationType {
    None = 'None',
}

export enum RequestErrorType {
    InvalidInput = 'invalid_input',
}

export enum Locales {
    En = 'en',
    Ru = 'ru',
    Es = 'es',
}

export type PublicInfoType = BehaviorSubject<{
    shouldShowCaptcha?: boolean;
    newCaptchaToken?: string;
    token?: string;
    isLoading?: boolean;
}>;

export enum Social {
	Google = 'google',
	Apple = 'apple',
	Facebook = 'facebook',
}
