import React, { FC, useEffect } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTATopicsPage.scss';
import { WebToAppService } from "../../../services/webtoapp.service";
import { Container } from "../../../../../symphony";
import { WTAComponentDrawer } from "../../pageComponents/WTAComponentDrawer/WTAComponentDrawer";
import { WTAPage } from "../../../types/webtoapp.types";

type Props = {
	page: WTAPage;
};

export const WTATopicsPage: FC<Props> = ({ page }) => {
	useStyles(s);

	const { footer, progressBar, topicPageHandler } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.disable();
		progressBar.show();
	}, []);

	return <WTAComponentDrawer
		components={page.content}
		pageOptions={page.pageOptions}
		handlers={{ onMultiChoiceChange: topicPageHandler }}
	/>;
};