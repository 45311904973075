import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './ProgressBar.scss';
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";

type Props = {};

export const ProgressBar: FC<Props> = () => {
	useStyles(s);

	const { progressValue } = useContainerData('wta', WebToAppStore, ['progressValue']);

	return (
		<div className="progress-bar">
			<div
				className="progress-bar-fill"
				style={{ width: `${progressValue}%` }}
			></div>
		</div>
	);
};
