import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAFlowInitializationPage.scss';

type Props = {};

export const WTAFlowInitializationPage: FC<Props> = ({ }) => {
	useStyles(s);
	return <div className="wta-init">
		<div className="wta-init-loader"></div>
	</div>;
};