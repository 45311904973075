import React, { FC, useEffect } from 'react';
import { WTAPage } from '../../../types/webtoapp.types';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTALoadingPage.scss';
import { WTAComponentDrawer } from '../../pageComponents/WTAComponentDrawer/WTAComponentDrawer';
import { Container } from '../../../../../symphony';
import { WebToAppService } from '../../../services/webtoapp.service';

type Props = {
	page: WTAPage;
};

export const WTALoadingPage: FC<Props> = ({ page }) => {
	useStyles(s);

	const { footer, progressBar, loadingPageHandler } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.hide();
		progressBar.hide();
		loadingPageHandler();
	}, []);

	return <WTAComponentDrawer
		components={page.content}
		pageOptions={page.pageOptions}
	/>;
};
