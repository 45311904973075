import { HeadersAuthorizationType } from '../global.interface';

export const constructUrl = (
	env: string,
	tail: string,
	version?: string,
): string => {
	if (version) {
		return `${env}/${version}/${tail}`;
	}
	return tail ? `${env}/${tail}` : `${env}`;
};

export const constructConfig = (payload: {
    version?: string;
    authorization?: HeadersAuthorizationType;
    session?: string;
    others?: object;
	isDynamicSession?: boolean;
}) => {
	let configs = null;

	if (payload?.version) {
		configs = {
			'sl-api-version': payload.version,
		};
	}
	if (payload?.authorization === HeadersAuthorizationType.None) {
		configs = {
			...configs,
			authorization: payload.authorization,
		};
	}
	if (payload?.session) {
		const key = payload.isDynamicSession ? 'sl-le-dynamic-session' : 'sl-le-session';

		configs = {
			...configs,
			[key]: payload.session,
		};
	}
	if (payload?.others) {
		configs = {
			...configs,
			...payload.others,
		};
	}

	return {
		headers: configs,
	};
};
