import React, { FC } from "react";
import Lottie from 'lottie-react';
import kodieHint from './kodieHint.json';
import mobileScroll from './mobileScroll.json';
import rocket from './rocket.json';
import blocks from './blocks.json';
import kodieLoading from './kodieLoading.json';

type Props = {
	animationName: string;
	style: React.CSSProperties;
};

export const Animation: FC<Props> = ({ animationName, style }) => {

	const getAnimation = () => {
		switch (animationName) {
			case 'kodieHint':
				return kodieHint;
			case 'mobileScroll':
				return mobileScroll;
			case 'rocket':
				return rocket;
			case 'blocks':
				return blocks;
			case 'kodieLoading':
				return kodieLoading;
		}
	};

	return <div style={style}><Lottie
		animationData={getAnimation()}
		autoPlay
		loop={true}
	/></div>;
};
