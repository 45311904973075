import { Touchpoint } from "../../api/public/subscription-api";

export enum PaywallState {
	open = 'open',
	closed = 'closed'
}

export type PaywallArguments = {
	touchpoint: Touchpoint;
	props: { [key: string]: string; };
};

export type PaywallCallbacks = {
	onClose?: (method?: 'back' | 'x') => void;
	onSubscribe?: () => void;
	onCountdownEnd?: () => void;
};

export enum PaywallNavigationPage {
	paywall = 'paywall',
	checkout = 'checkout',
	congrats = 'congrats'
}

export type PaywallNavigation = {
	page: PaywallNavigationPage;
	step: string;
};

export const CardFields = ['number', 'cvv', 'expirationDate', 'postalCode'];

export class PaywallErroredData { }

export const PaywallOfferModalId = 'PaywallOfferModalId';