import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTACompleteAccountPage.scss';
import { Container, i18n } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { SlIcon } from "@sololearnorg/icons";
import { Padding } from "../../shared/Padding/Padding";
import { WTAButton } from "../../shared/WTAButton/WTAButton";
import { motion } from "framer-motion";
import { Heading } from "../../pageComponents/Heading/Heading";
import { GoogleLogin } from 'react-google-login-component';
import { GoogleLogoSVG, SignInWithApple } from "../../shared/icons";
import { GOOGLE_ID } from "../../../../../login/private/constants";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";

type Props = {};
export const WTACompleteAccountPage: FC<Props> = ({ }) => {
	useStyles(s);
	const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [password, setPassword] = useState<{ password: string, isValid: boolean; }>(null);

	const inputRef = useRef<HTMLInputElement>(null);
	const {
		footer,
		progressBar,
		completeRegistrationPageHandler,
		googleLoginHandler,
		appleLoginHandler,
		appleLoginInit
	} = Container.take('wta', WebToAppService);

	const { socialAccountError } = useContainerData('wta', WebToAppStore, [
		'socialAccountError'
	]);

	useEffect(() => {
		footer.hide();
		progressBar.hide();
		appleLoginInit();
	}, []);

	const handleButtonClick = () => {
		if (!showPasswordForm) {
			setShowPasswordForm(true);
			setTimeout(() => {
				inputRef.current?.focus();
			}, 500);
		} else {
			setLoading(true);
			completeRegistrationPageHandler({
				password: password.password
			});
		}
	};

	const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword({
			password: event.target.value,
			isValid: /^[^\s]{6,}$/.test(event.target.value)
		});
	};

	return <Padding className="wta-complete-account">
		<div className="wta-complete-account-logo">
			<SlIcon iconId="sololearn" />
		</div>
		<Heading
			locKey="wta.complete-account.title"
			level={2}
			style={{
				paddingBottom: 0
			}}
		/>
		<div className="wta-complete-account-socials">
			{socialAccountError && <p className="wta-complete-account-error">
				{i18n.t('wta.social-account.error')}
			</p>}
			<GoogleLogin
				socialId={GOOGLE_ID}
				scope="profile email"
				prompt="select_account"
				fetchBasicProfile={false}
				responseHandler={googleLoginHandler}
				className="wta-complete-account-google-signin-button"
			>
				<GoogleLogoSVG />
				<span>{i18n.t('auth.google-signin-title')}</span>
			</GoogleLogin>
			<div
				className="wta-complete-account-apple-signin-button"
				onClick={appleLoginHandler}
			>
				<SignInWithApple />
			</div>
		</div>

		<div className="wta-complete-account-separator">
			<span>{i18n.t('or')}</span>
		</div>
		<div className="wta-complete-account-password">
			<motion.div
				className="wta-complete-account-password-form"
				initial={{ opacity: 0, height: 0, marginBottom: 0 }}
				animate={showPasswordForm ? { opacity: 1, height: "auto", marginBottom: '32px' } : {}}
				transition={{ duration: 0.5 }}
			>
				<input
					ref={inputRef}
					type="password"
					className="wta-complete-account-password-input"
					placeholder={i18n.t('wta.complete-account.input-placeholder')}
					onChange={onPasswordChange}
					disabled={loading}
				/>
			</motion.div>

			<WTAButton
				onClick={handleButtonClick}
				locKey={showPasswordForm ? "wta.complete-account.cta" : "wta.complete-account.button"}
				background={showPasswordForm ? 'purple' : 'white'}
				className="wta-complete-account-button"
				disabled={showPasswordForm && !password?.isValid}
				loading={loading}
			/>
		</div>
	</Padding >;
};
