import { WTAComponentType, WTAJourney, WTAPageType } from "./types/webtoapp.types";

const GIFURL = 'https://lecontent.sololearn.com/material-images/59cea34f4b11441b9243ce6013a0a670-1.02.01.gif';
export const MOCKPAGES: WTAJourney = {
	flow: {
		startPageId: 1111111,
		flowId: 1324,
		selections: []
	},
	pages: [
		{
			id: 1111111,
			type: WTAPageType.getStarted,
			title: 'Learning AI got \nsimplified',
			content: [
				{
					type: WTAComponentType.pageTitle,
					data: 'Learning AI got \nsimplified'
				},
				// {
				// 	type: WTAComponentType.testimonials,
				// 	testimonialKeys: ['wta.page.254.h2', 'wta.page.249.user-review', 'wta.page.246.user-review']
				// },
				{
					type: WTAComponentType.image,
					data: 'https://picsum.photos/seed/a/150/70'
				}
			],
		},
		{
			id: 20,
			type: WTAPageType.paywall
		},
		{
			id: 1,
			type: WTAPageType.generic,
			content: [
				{
					type: WTAComponentType.h2,
					data: "Sololearn is the fastest way to learn new skills"
				},
				{
					type: WTAComponentType.image,
					data: GIFURL
				}
			]
		},
		{
			id: 18,
			type: WTAPageType.emailUpdate
		},
		{
			id: 19,
			type: WTAPageType.completeAccountRegistration
		},

		{
			id: 2,
			type: WTAPageType.generic,
			meta: {
				style: {
					background: 'purple'
				}
			},
			content: [
				{
					type: WTAComponentType.h1,
					data: 'Let’s get started!',
					style: {
						color: '#fff'
					}
				},
				{
					type: WTAComponentType.paragraph,
					data: 'Please, answer a few questions to personalize your experience.',
					style: {
						color: '#fff',
						paddingBottom: '20%'
					}
				},
				{
					type: WTAComponentType.image,
					data: GIFURL
				}
			]
		},
		{
			id: 3,
			type: WTAPageType.singleChoice,
			content: [
				{
					type: WTAComponentType.h2,
					data: 'First, tell us where did you hear about Sololearn?'
				},
				{
					type: WTAComponentType.singleChoiceList,
					data: null
				}
			],
			pageOptions: [
				{ value: 1, label: 'APP STORE', id: 1 },
				{ value: 2, label: 'FRIENDS OR FAMILY', id: 2 },
				{ value: 3, label: 'YOUTUBE', id: 3 },
				{ value: 4, label: 'INSTAGRAM', id: 4 },
				{ value: 5, label: 'TIKTOK', id: 5 },
				{ value: 6, label: 'FACEBOOK', id: 6 },
				{ value: 7, label: 'GOOGLE', id: 7 },
				{ value: 8, label: 'OTHER', id: 8 }
			]
		},
		{
			id: 4,
			type: WTAPageType.singleChoice,
			content: [
				{
					type: WTAComponentType.h2,
					data: 'What best describes you at the moment?'
				},
				{
					type: WTAComponentType.singleChoiceList,
					data: null
				}
			],
			pageOptions: [
				{ value: 1, label: 'I study', id: 9 },
				{ value: 2, label: 'I work', id: 10 },
				{ value: 3, label: 'I\'m an entrepreneur', id: 11 },
				{ value: 8, label: 'OTHER', id: 12 }
			]
		},
		{
			id: 5,
			type: WTAPageType.generic,
			content: [
				{
					type: WTAComponentType.h2,
					data: "Sololearn’s bite-sized materials are designed to fit your day"
				},
				{
					type: WTAComponentType.image,
					data: GIFURL
				}
			]
		},
		{
			id: 6,
			type: WTAPageType.singleChoice,
			content: [
				{
					type: WTAComponentType.h2,
					data: 'In the last week how much time have you spent on learning something new?'
				},
				{
					type: WTAComponentType.singleChoiceList,
					data: null
				}
			],
			pageOptions: [
				{ value: 1, label: '3+ hours', id: 13 },
				{ value: 2, label: '1-3 hours', id: 14 },
				{ value: 3, label: 'under 1 hour', id: 15 },
				{ value: 8, label: 'I didn’t', id: 16 }
			]
		},
		{
			id: 7,
			type: WTAPageType.generic,
			meta: {
				style: {
					background: 'purple'
				}
			},
			content: [
				{
					type: WTAComponentType.userReview,
					data: "Lessons are quick so you can learn at anytime and since it's like a game it's very motivating!",
					userName: "Kimberly Doctor",
					style: {
						flex: 1
					}
				},
				{
					type: WTAComponentType.svgIcon,
					data: 'appleAward',
					style: {
						color: "#BAA2E7"
					}
				}
			]
		},
		{
			id: 8,
			type: WTAPageType.singleChoice,
			content: [
				{
					type: WTAComponentType.h2,
					data: 'What’s your age group?'
				},
				{
					type: WTAComponentType.singleChoiceList,
					data: null
				}
			],
			pageOptions: [
				{ value: 1, label: '18 or under' },
				{ value: 2, label: '19-24' },
				{ value: 3, label: '25-34' },
				{ value: 4, label: '35-44' },
				{ value: 5, label: '45 or over' }
			]
		},
		{
			id: 9,
			type: WTAPageType.generic,
			content: [
				{
					type: WTAComponentType.h2,
					data: "Sololearn’s proven way of teaching is designed for anyone to learn easily"
				},
				{
					type: WTAComponentType.image,
					data: GIFURL
				}
			]
		},
		{
			id: 10,
			type: WTAPageType.generic,
			meta: {
				style: {
					background: 'purple'
				}
			},
			content: [
				{
					type: WTAComponentType.userReview,
					data: "The courses have excellent teaching methods. You practice a lot directly in the app. I am really enjoying it.",
					userName: "Roger Carregal",
				},
				{
					type: WTAComponentType.image,
					data: GIFURL
				}
			]
		},
		{
			id: 11,
			type: WTAPageType.generic,
			content: [
				{
					type: WTAComponentType.h2,
					data: "Lessons are visual and interactive to keep you engaged while learning"
				},
				{
					type: WTAComponentType.image,
					data: GIFURL
				}
			]
		},
		{
			id: 12,
			type: WTAPageType.singleChoice,
			content: [
				{
					type: WTAComponentType.h2,
					data: 'Why do you want to learn AI?'
				},
				{
					type: WTAComponentType.singleChoiceList,
					data: null
				}
			],
			pageOptions: [
				{ value: 1, label: 'To upgrade my skillset' },
				{ value: 2, label: 'to stay competitive' },
				{ value: 3, label: 'to become more efficient' },
				{ value: 4, label: 'For a project I work on' },
				{ value: 5, label: 'I am just curious' }
			]
		},
		{
			id: 13,
			type: WTAPageType.generic,
			meta: {
				style: {
					background: 'purple'
				}
			},
			content: [
				{
					type: WTAComponentType.userReview,
					data: "Easy to understand and lessons are bite-sized",
					userName: "Jacob Prince",
				},
				{
					type: WTAComponentType.image,
					data: GIFURL
				}
			]
		},
		{
			id: 14,
			type: WTAPageType.topics,
			content: [
				{
					type: WTAComponentType.h2,
					data: 'What topics in the scope of AI interest you?'
				},
				{
					type: WTAComponentType.multiChoiceCards,
					data: null
				}
			],
			pageOptions: [
				{ value: 1, label: 'WRITE BETTER', iconUrl: 'https://picsum.photos/seed/a/150/70' },
				{ value: 2, label: 'UNDERSTAND DATA', iconUrl: 'https://picsum.photos/seed/b/150/70' },
				{ value: 3, label: 'HOW LLMs WORK', iconUrl: 'https://picsum.photos/seed/c/150/70' },
				{ value: 4, label: 'GENERATE IMAGES', iconUrl: 'https://picsum.photos/seed/d/150/70' },
				{ value: 5, label: 'IMPROVE PRODUCTIVITY', iconUrl: 'https://picsum.photos/seed/e/150/70' },
				{ value: 6, label: 'BRainstorm with ai', iconUrl: 'https://picsum.photos/seed/f/150/70' }
			]
		},
		{
			id: 15,
			type: WTAPageType.generic,
			content: [
				{
					type: WTAComponentType.h2,
					data: "Sololearn helps you learn these topics with hands on practice"
				},
				{
					type: WTAComponentType.image,
					data: GIFURL
				}
			]
		},
		{
			id: 16,
			type: WTAPageType.quiz,
			content: [
				{
					type: WTAComponentType.h2,
					data: 'Is this interesting to you?'
				},
				{
					type: WTAComponentType.likeDislike,
					data: '',
					questions: [
						{
							id: 1,
							imageUrl: 'https://picsum.photos/seed/ab/300/234',
							question: 'Writing emails and messages',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [1, 5]
						},
						{
							id: 2,
							imageUrl: 'https://picsum.photos/seed/ac/300/234',
							question: 'Business writing',
							description: 'memos, proposals business plans',
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [1]
						},
						{
							id: 3,
							imageUrl: 'https://picsum.photos/seed/ad/300/234',
							question: 'Creative writing',
							description: 'stories, poems, novels',
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [1, 5]
						},
						{
							id: 4,
							imageUrl: 'https://picsum.photos/seed/ae/300/234',
							question: 'Writing social media posts',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [1, 5]
						},
						{
							id: 5,
							imageUrl: 'https://picsum.photos/seed/af/300/234',
							question: 'Writing marketing materials',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [1, 5]
						},
						{
							id: 6,
							imageUrl: 'https://picsum.photos/seed/ag/300/234',
							question: 'Data visualization',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [2, 5]
						},
						{
							id: 7,
							imageUrl: 'https://picsum.photos/seed/ah/300/234',
							question: 'Data interpretation',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [2]
						},
						{
							id: 8,
							imageUrl: 'https://picsum.photos/seed/ai/300/234',
							question: 'Data segmentation',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [2]
						},
						{
							id: 9,
							imageUrl: 'https://picsum.photos/seed/aj7/300/234',
							question: 'Data analysis',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [2, 5]
						},
						{
							id: 10,
							imageUrl: 'https://picsum.photos/seed/aj6/300/234',
							question: 'Generating marketing creatives',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [4, 5]
						},
						{
							id: 11,
							imageUrl: 'https://picsum.photos/seed/aj5/300/234',
							question: 'Generating creatives for paid ads',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [4, 5]
						},
						{
							id: 12,
							imageUrl: 'https://picsum.photos/seed/aj4/300/234',
							question: 'Generating images for social media',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [4, 5]
						},
						{
							id: 13,
							imageUrl: 'https://picsum.photos/seed/aj3/300/234',
							question: 'How GenAI works',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [3, 6]
						},
						{
							id: 14,
							imageUrl: 'https://picsum.photos/seed/aj2/300/234',
							question: 'AI: Ethics and safety',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [3]
						},
						{
							id: 15,
							imageUrl: 'https://picsum.photos/seed/aj1/300/234',
							question: 'AI prompting skills',
							description: null,
							aliases: ['genai-in-practice', 'data-ai'],
							topicIds: [3, 6, 5]
						}
					]
				}
			]
		},
		{
			id: 17,
			type: WTAPageType.loading,
			content: [
				{
					type: WTAComponentType.loader,
					data: 'Please wait while we personalize your experience',
					animation: 'KODIE'
				}
			]
		}

	]
};
