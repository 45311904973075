import React, { FC } from "react";
import { i18n } from "../../../../../symphony";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Description.scss';

type Props = {
	locKey: string;
	style: React.CSSProperties;
};

export const Description: FC<Props> = ({ locKey, style }) => {
	useStyles(s);
	return <p style={style} className="wta-description">{i18n.t(locKey)}</p>;
};
