import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Image.scss';

type Props = {
	src: string;
	alt?: string;
	style?: React.CSSProperties;
};

export const Image: FC<Props> = ({ src, style = {}, alt = 'onboarding image' }) => {
	useStyles(s);
	return <img
		className="wta-image"
		style={style}
		src={src}
		alt={alt}
	/>;
};
