import { BehaviorSubject } from "rxjs";
import { Service } from "../../symphony";
import { PaywallNavigation, PaywallState } from "./paywalls.constants";
import { PaymentProduct, PaywallData, Touchpoint } from "../../api/public/subscription-api";

@Service()
export class PaywallsStore {

	public paywallState = new BehaviorSubject<PaywallState>(PaywallState.closed);
	public paywallsMap = new BehaviorSubject<{ [hash: string]: PaywallData; }>({});
	public paywallData = new BehaviorSubject<PaywallData>(null);
	public currentTouchpoint = new BehaviorSubject<Touchpoint>(null);
	public paywallNavigation = new BehaviorSubject<PaywallNavigation>(null);

	public paymentProducts = new BehaviorSubject<PaymentProduct[]>(null);
	public selectedProductKey = new BehaviorSubject<string>(null);
	public offerProductKey = new BehaviorSubject<string>(null);
	public customCTAText = new BehaviorSubject<string>(null);

	public countdown = new BehaviorSubject<number>(null);
}
