import React, { FC, useEffect } from "react";
import { Container, i18n } from "../../../../../symphony";
import { CheckoutService } from "../../../services/checkout.service";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { BraintreeInputField } from "../BraintreeInputField/BraintreeInputField";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './CardPayment.scss';
import { Select, Option } from '@sololearnorg/sol';

export const CardPayment: FC = () => {
	useStyles(s);
	const {
		cardError,
		zipCodeRequired,
		transactionInProcess
	} = useContainerData('paywalls', CheckoutStore, [
		'cardError',
		'zipCodeRequired',
		'transactionInProcess'
	]);
	const { initCard, changeZipCodeRequired, clearCardErrors } = Container.take('paywalls', CheckoutService);

	useEffect(() => {
		initCard();
		clearCardErrors();
	}, []);
	return <div>
		{cardError && <div className="card-error">{cardError}</div>}
		<div className="card-fields__container">
			<div className="card-fields__card">
				<BraintreeInputField
					id="card-number"
					label={i18n.t('web-checkout.card-number-label')}
				/>
			</div>
			<div className="card-fields__exp">
				<BraintreeInputField
					id="expiration-date"
					label={i18n.t('web-checkout.expiration-label')}
				/>
			</div>
			<div className="card-fields__cvv">
				<BraintreeInputField
					id="cvv"
					label={i18n.t('web-checkout.security-label')}
				/>
			</div>
			<div className="card-fields__country">
				<div className="card-fields__label">{i18n.t('web-checkout.country-label')}</div>
				<Select
					disabled={transactionInProcess}
					value={zipCodeRequired ? '1' : '0'}
					name="option"
					onValueChange={(val: '0' | '1') => { changeZipCodeRequired(val === '0' ? false : true); }}
					className="card-fields__country-select"
				>
					<React.Fragment key=".0">
						<Option value="1">
							{i18n.t('web-checkout.usa-uk-canada')}
						</Option>
						<Option value="0">
							{i18n.t('web-checkout.other-countries')}
						</Option>
					</React.Fragment>
				</Select>
			</div>
			<div className={`card-fields__zip ${!zipCodeRequired ? 'hidden' : ''}`}>
				<BraintreeInputField
					id="postal-code"
					label={i18n.t('web-checkout.zip-label')}
				/>
			</div>
		</div>
	</div>;
};