import React, { FC, useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAQuizPage.scss';
import { WebToAppService } from '../../../services/webtoapp.service';
import { Container } from '../../../../../symphony';
import { WTAComponentType, WTAPage, WTAQuizQuestion } from '../../../types/webtoapp.types';
import { WTAComponentDrawer } from '../../pageComponents/WTAComponentDrawer/WTAComponentDrawer';

type Props = {
	page: WTAPage;
};

export const WTAQuizPage: FC<Props> = ({ page }) => {
	useStyles(s);
	const [questions, setQuestions] = useState<WTAQuizQuestion[]>();

	const { footer, progressBar, defineQuizQuestions, quizPageHandler } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.hide();
		progressBar.show();
	}, []);

	useEffect(() => {
		const quizQuestions = page.content.find(c => c.type === WTAComponentType.likeDislike).questions;
		setQuestions(defineQuizQuestions(quizQuestions));
	}, [page]);

	return <div>
		<WTAComponentDrawer
			components={page.content}
			pageOptions={page.pageOptions}
			quizQuestions={questions}
			handlers={{ onQuizChange: quizPageHandler }}
		/>
	</div>;
};
