import React, { FC, useEffect } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTADownloadAppPage.scss';
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { useIsMobile } from "../../shared/useIsMobile";
import { SlIcon } from "@sololearnorg/icons";
import { Heading } from "../../pageComponents/Heading/Heading";
import { useDeviceType } from "../../shared/useDeviceType";
import { Description } from "../../pageComponents/Description/Description";
import { Padding } from "../../shared/Padding/Padding";
import { DownloadOnAppStore, GetItOnGooglePlay } from "../../shared/icons";

type Props = {};

export const WTADownloadAppPage: FC<Props> = () => {
	useStyles(s);

	const { footer, progressBar } = Container.take('wta', WebToAppService);
	const isMobile = useIsMobile();
	const deviceType = useDeviceType();

	useEffect(() => {
		footer.hide();
		progressBar.hide();
	}, []);

	return <Padding className="wta-download">
		<div className="wta-download-logo">
			<SlIcon iconId={'sololearnRound'} />
		</div>
		<Heading level={1} locKey="wta.download-app.title" style={{
			paddingBottom: 0
		}} />
		<Heading level={4} locKey="wta.download-app.subtitle" style={{
			paddingBottom: 0
		}} />
		<Description locKey="wta.download-app.mobile-app.lablel" style={{}} />
		<div className="wta-download-apps">
			{deviceType !== 'android' && <div className="wta-download-icon">
				<a target="_blank" href="https://apps.apple.com/us/app/sololearn-learn-to-code/id1210079064"><DownloadOnAppStore /></a>
			</div>}
			{deviceType !== 'ios' && <div className="wta-download-icon">
				<a target="_blank" href="https://play.google.com/store/apps/details?id=com.sololearn&hl=en"><GetItOnGooglePlay /></a>
			</div>}
		</div>
		<div className="wta-download-separator"><span>OR</span></div>
		<a href="/">
			<Description locKey="wta.download-app.web-version.label" style={{
				fontSize: "16px"
			}} />
		</a>
	</Padding >;
};