import React from 'react';

export default function StarsIcon() {
	return (
		<svg width="90" height="20" viewBox="0 0 180 37" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M16.1534 4.85978C16.8365 3.21719 19.1635 3.21719 19.8466 4.85978L22.2919 10.739C22.5799 11.4314 23.2312 11.9046 23.9787 11.9645L30.3258 12.4733C32.0991 12.6155 32.8182 14.8285 31.4671 15.9859L26.6313 20.1282C26.0617 20.6161 25.813 21.3817 25.987 22.1112L27.4644 28.3049C27.8772 30.0353 25.9947 31.403 24.4765 30.4757L19.0425 27.1567C18.4025 26.7658 17.5975 26.7658 16.9575 27.1567L11.5235 30.4757C10.0053 31.403 8.12281 30.0353 8.53558 28.3049L10.013 22.1112C10.187 21.3817 9.93827 20.6161 9.36869 20.1282L4.53289 15.9859C3.18182 14.8285 3.90087 12.6155 5.67418 12.4733L12.0213 11.9645C12.7688 11.9046 13.4201 11.4314 13.7081 10.739L16.1534 4.85978Z" fill="#FFA310" />
			<path d="M52.1534 4.85978C52.8365 3.21719 55.1635 3.21719 55.8466 4.85978L58.2919 10.739C58.5799 11.4314 59.2312 11.9046 59.9787 11.9645L66.3258 12.4733C68.0991 12.6155 68.8182 14.8285 67.4671 15.9859L62.6313 20.1282C62.0617 20.6161 61.813 21.3817 61.987 22.1112L63.4644 28.3049C63.8772 30.0353 61.9947 31.403 60.4765 30.4757L55.0425 27.1567C54.4025 26.7658 53.5975 26.7658 52.9575 27.1567L47.5235 30.4757C46.0053 31.403 44.1228 30.0353 44.5356 28.3049L46.013 22.1112C46.187 21.3817 45.9383 20.6161 45.3687 20.1282L40.5329 15.9859C39.1818 14.8285 39.9009 12.6155 41.6742 12.4733L48.0213 11.9645C48.7688 11.9046 49.4201 11.4314 49.7081 10.739L52.1534 4.85978Z" fill="#FFA310" />
			<path d="M88.1534 4.85978C88.8365 3.21719 91.1635 3.21719 91.8466 4.85978L94.2919 10.739C94.5799 11.4314 95.2312 11.9046 95.9787 11.9645L102.326 12.4733C104.099 12.6155 104.818 14.8285 103.467 15.9859L98.6313 20.1282C98.0617 20.6161 97.813 21.3817 97.987 22.1112L99.4644 28.3049C99.8772 30.0353 97.9947 31.403 96.4765 30.4757L91.0425 27.1567C90.4025 26.7658 89.5975 26.7658 88.9575 27.1567L83.5235 30.4757C82.0053 31.403 80.1228 30.0353 80.5356 28.3049L82.013 22.1112C82.187 21.3817 81.9383 20.6161 81.3687 20.1282L76.5329 15.9859C75.1818 14.8285 75.9009 12.6155 77.6742 12.4733L84.0213 11.9645C84.7688 11.9046 85.4201 11.4314 85.7081 10.739L88.1534 4.85978Z" fill="#FFA310" />
			<path d="M124.153 4.85978C124.837 3.21719 127.163 3.21719 127.847 4.85978L130.292 10.739C130.58 11.4314 131.231 11.9046 131.979 11.9645L138.326 12.4733C140.099 12.6155 140.818 14.8285 139.467 15.9859L134.631 20.1282C134.062 20.6161 133.813 21.3817 133.987 22.1112L135.464 28.3049C135.877 30.0353 133.995 31.403 132.476 30.4757L127.043 27.1567C126.402 26.7658 125.598 26.7658 124.957 27.1567L119.524 30.4757C118.005 31.403 116.123 30.0353 116.536 28.3049L118.013 22.1112C118.187 21.3817 117.938 20.6161 117.369 20.1282L112.533 15.9859C111.182 14.8285 111.901 12.6155 113.674 12.4733L120.021 11.9645C120.769 11.9046 121.42 11.4314 121.708 10.739L124.153 4.85978Z" fill="#FFA310" />
			<path d="M160.153 4.85978C160.837 3.21719 163.163 3.21719 163.847 4.85978L166.292 10.739C166.58 11.4314 167.231 11.9046 167.979 11.9645L174.326 12.4733C176.099 12.6155 176.818 14.8285 175.467 15.9859L170.631 20.1282C170.062 20.6161 169.813 21.3817 169.987 22.1112L171.464 28.3049C171.877 30.0353 169.995 31.403 168.476 30.4757L163.043 27.1567C162.402 26.7658 161.598 26.7658 160.957 27.1567L155.524 30.4757C154.005 31.403 152.123 30.0353 152.536 28.3049L154.013 22.1112C154.187 21.3817 153.938 20.6161 153.369 20.1282L148.533 15.9859C147.182 14.8285 147.901 12.6155 149.674 12.4733L156.021 11.9645C156.769 11.9046 157.42 11.4314 157.708 10.739L160.153 4.85978Z" fill="#FFA310" />
		</svg>
	);
}
