import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SlNavBarPositions, SlNavbarThemes } from '../../../features/shared/public/globalInterfaces/globalInterfaces';
import { SlHelmet } from '../../../features/shared/public/SlHelmet/SlHelmet';
import { SlError } from '../../../features/shared/public/SlError/SlError';
import { SlNavbarState } from '../../../features/navigation/private/global-interface';
import { SlFooter } from '../../../features/navigation/public/SlFooter/SlFooter';
import { setNavigationConfigs } from '../../utils/navigationManager';

const defaultConfigs = {
	title: 'Oops, Error!',
	info: 'Sorry! It seems something went wrong on our end!',
	imgUrl: 'https://assets.sololearn.com/404.png',
	linkText: 'Go Back',
	hasBackButton: true,
};

export const Error = React.memo(() => {
	const [isReady, setIsReady] = useState<boolean>(false);

	const location = useLocation();
	let configs = defaultConfigs;

	useEffect(() => {
		const errorCode = new URLSearchParams(location.search).get('code');

		if (errorCode) {
			configs = { ...defaultConfigs, title: `Oops,${errorCode ? ` ${errorCode}` : ''} Error!` };
		}

		setNavigationConfigs({
			state: SlNavbarState.shown,
			theme: SlNavbarThemes.light,
			position: SlNavBarPositions.relative,
		});
		setIsReady(true);
		return () => {
			setNavigationConfigs({
				state: SlNavbarState.hidden,
			});
		};
	}, []);

	return (
		<>
			<SlHelmet
				config={{
					title: 'Sololearn: Learn to Code',
				}}
			/>
			{isReady ? (
				<>
					<SlError configs={configs} />
					<SlFooter />
				</>
			) : null}
		</>
	);
});
