export const injectPrice = (value: string, label: string): string => {
	return label?.replace(/{\{[^}]*\}\}/, `$${value} USD`);
};

export const injectPercentage = (value: string, label: string): string => {
	return label?.replace(/{\{[^}]*\}\}/, `${value}`);
};

export const injectMonthlyAndAnnualPrices = (monthly: string, annual: string, label: string): string => {
	return label
		.replace('${{discountedMonthly}}', `$${monthly} USD`)
		.replace('${{discountedPrice}}', `$${annual} USD`);
};

export const injectTrialDays = (value: string, label: string) => {
	return label?.replace("{{trial_days}}", `${value}`);
}

export const calculateSalePercentage = (originalPrice: number, discountedPrice: number): string => {
	const discountAmount = originalPrice - discountedPrice;
	const salePercentage = (discountAmount / originalPrice) * 100;
	return salePercentage.toFixed(0);
}

export const getDayFromToday = (days) => {
	const result = new Date();
	result.setDate(result.getDate() + days);
	return result.getDate();
};
