import React, { FC, useEffect } from 'react';
import { WTAPage } from "../../../types/webtoapp.types";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTASingleChoicePage.scss';
import { WTAComponentDrawer } from '../../pageComponents/WTAComponentDrawer/WTAComponentDrawer';
import { Container } from '../../../../../symphony';
import { WebToAppService } from '../../../services/webtoapp.service';

type Props = {
	page: WTAPage;
};

export const WTASingleChoicePage: FC<Props> = ({ page }) => {
	useStyles(s);

	const { footer, progressBar, singleChoicePageHandler } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.disable();
		progressBar.show();
	}, []);

	return <WTAComponentDrawer
		components={page.content}
		pageOptions={page.pageOptions}
		handlers={{ onSingleChoiceListChange: singleChoicePageHandler }}
	/>;
};
