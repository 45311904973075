import React, { FC, useEffect } from "react";
import { Container } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { SlIcon } from "@sololearnorg/icons";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAAIOrHumanPage.scss';

type Props = {};

export const WTAAIOrHumanPage: FC<Props> = () => {
	useStyles(s);
	const { footer, progressBar } = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.hide();
		progressBar.hide();
	}, []);

	const gameLogo: React.ReactNode = <div className="wta-ai-game-logo">
		<div className="wta-ai-game-logo-background left"></div>
		<div className="wta-ai-game-logo-background right"></div>
		<div className="wta-ai-game-logo-emoji">🤖</div>
		<span>AI OR HUMAN</span>
		<div className="wta-ai-game-logo-emoji">👨</div>
	</div>;

	return <div className="wta-ai-game">
		<div className="wta-ai-game-sololearn">
			<SlIcon iconId="sololearn" />
		</div>
		<h3 className="wta-ai-game-title">Let's start with a quick game</h3>
		{gameLogo}
		<img src="https://s7.ezgif.com/tmp/ezgif-7-15b05b42ed.gif" alt="" />
		<p>Can you tell the differnce?</p>
		<button>Start the Game</button>
	</div>;
};
