import { useState, useEffect } from 'react';

type DeviceType = 'ios' | 'android' | 'desktop';

export const useDeviceType = (): DeviceType => {
	const getDeviceType = (): DeviceType => {
		const userAgent = navigator.userAgent;

		if (/android/i.test(userAgent)) {
			return 'android';
		} else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
			return 'ios';
		} else {
			return 'desktop';
		}
	};

	const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType);

	useEffect(() => {
		const handleResize = () => {
			setDeviceType(getDeviceType());
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return deviceType;
};
