import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './UserReview.scss';
import { i18n } from '../../../../../symphony';
import StarsIcon from './StarsIcon';
import { SlIcon } from '@sololearnorg/icons';
import { AppleAwardForGenerativeAI, ReviewBackground } from '../../shared/icons';

type Props = {
	review: string;
	userName: string;
	style?: React.CSSProperties;
};

export const UserReview: FC<Props> = ({ review, userName, style }) => {
	useStyles(s);
	return (
		<div style={style} className='wta-review-container'>
			<div className='wta-review'>
				<div className='wta-triangle'></div>
				<span className='wta-review-text'>{i18n.t(review)}</span>
				<span className='wta-review-username'>{i18n.t(userName)}</span>
				<div className="wta-rating">
					<span>{i18n.t('wta.user-review.stars.title')}</span>
					<StarsIcon />
				</div>
			</div>
			<div className="wta-review-background">
				<ReviewBackground />
				<ReviewBackground />
			</div>
			<div className='wta-review-badges'>
				<div className="wta-review-badge">
					<AppleAwardForGenerativeAI />
				</div>
			</div>
		</div>
	);
};
