import { Container } from '../container.global';
import { StorageService, StorageType } from '../storage.service';

export const shouldBeRemovedAfterLogout = [
	'onboardingSelection',
	'psychoPages',
	'hardBlocker',
	'adSelectedCourseAlias',
	'selectedCourseInfo',
	'dynamicOnboardingSelection',
];

export const cleanupStorage = (
	arr: string[],
	storageType: StorageType,
): void => {
	const storage = Container.take('global', StorageService);

	arr.forEach((key) => storage.remove(key, storageType));
};
