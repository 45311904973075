import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './SvgIcon.scss';
import { SlIcon } from "@sololearnorg/icons";

type Props = {
	iconId: string;
	style: React.CSSProperties;
};
export const SvgIcon: FC<Props> = ({ iconId, style }) => {
	useStyles(s);
	return <div style={style}>
		<SlIcon iconId={iconId} />
	</div>;
};
