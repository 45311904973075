import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Padding.scss';

type Props = {
	children: React.ReactNode;
	className?: string;
};

export const Padding: FC<Props> = ({ children, className }) => {
	useStyles(s);
	return <div className={`padding ${className || ''}`}>{children}</div>;
};