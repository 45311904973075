import React, { useState, useEffect } from 'react';

export const useIsMobile = (): boolean => {
	const getIsMobile = (): boolean => {
		const userAgent = navigator.userAgent;
		const mobileDevices = [
			/Android/i,
			/webOS/i,
			/iPhone/i,
			/iPad/i,
			/iPod/i,
			/BlackBerry/i,
			/Windows Phone/i,
		];
		return mobileDevices.some((mobileDevice) => userAgent.match(mobileDevice));
	};
	const [isMobile, setIsMobile] = useState<boolean>(getIsMobile);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(getIsMobile());
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isMobile;
};
