import { AxiosError, AxiosResponse } from 'axios';

import { IResponse, IError, ErrorCodes } from './global.interface';
import { Container, AuthService } from '../../symphony';

export const ErrorCodeMessages: { [key: number]: string } = {
	[ErrorCodes.Unauthorized]: 'Unauthorized',
	[ErrorCodes.Forbidden]: 'Forbidden',
	[ErrorCodes.NotFound]: 'Not found',
};

interface ThrownError {
    isThrownError: boolean;
    errors: IError[];
}

const defineInvalidInputMessage = (errData: any) => {
	let errMessage: string;
	errData.invalidParams?.forEach((err) => {
		if (err.field === 'email' && err.problem === 'invalid') {
			errMessage = 'Email is incorrect';
		}
		if (err.field === 'email' && err.problem === 'existing_email') {
			errMessage = 'Email is already registered';
		}
		if (err.field === 'name' && err.problem === 'invalid') {
			errMessage = 'Name is incorrect';
		}
		if (err.field === 'password' && err.problem === 'invalid') {
			errMessage = 'Password is incorrect';
		}
	});
	return errMessage;
};

const handleResponseErrorMessage = (res: AxiosResponse) => {
	if (res?.data?.type) {
		switch (res.data.type) {
		case 'invalid_credentials':
			return 'Invalid username or password.';
		case 'not_activated':
			return 'Your account is not activated. Please check your email for activation instructions.';
		case 'blocked_user':
			return 'Your account is blocked.';
		case 'quota_exceeded':
		case 'login_limit_exceeded':
		case 'limit_reached':
			return res.data.status === 400
				? 'You reached the maximum amount of attempts, please come back later to try again.'
				: '';
		case 'invalid_input':
			return (
				defineInvalidInputMessage(res.data)
                    || 'Something went wrong'
			);
		default:
			return 'Something went wrong';
		}
	}

	return null;
};

export function handleResponse<T extends IResponse>(response: T): T {
	if (response.success || response.success === undefined) return response as T;
	const error = {
		isThrownError: true,
		errors: response.errors,
	};
	throw error;
}

export function handleError(error: AxiosError | ThrownError, url?: string): never {
	if (
		(error as AxiosError)?.response?.status === 401
        && !(error as AxiosError)?.response?.data
	) {
		const authService = Container.take('global', AuthService);

		authService.logout({
			pathname: `/users/login?returnUrl=${encodeURIComponent(
				`${window.location.pathname}${window.location.search}`,
			)}`,
			type: 'internal',
		});
		const errors = [
			{
				code: (error as AxiosError).response.status,
				message:
                    ErrorCodeMessages[(error as AxiosError).response.status],
				url: url ?? (error as AxiosError)?.config?.url,
			},
		] as IError[];
		throw errors;
	}
	if ((error as ThrownError).isThrownError) {
		throw (error as ThrownError).errors;
	} else if (
		(error as AxiosError).isAxiosError
        && (error as AxiosError).response
	) {
		const response: AxiosResponse = (error as AxiosError).response;

		const errors = [
			{
				code: response.status,
				message:
                    response.statusText || handleResponseErrorMessage(response),
				url: url ?? (error as AxiosError)?.config?.url,
			},
		] as IError[];
		throw response.data?.errors || errors; // TODO: think!
	} else {
		if (
			(error as AxiosError)?.response?.status
            === ErrorCodes.ServiceUnavailable
		) {
			window.location.reload();
		}
		const errors = [
			{
				code: (error as AxiosError)?.response?.status || 555,
				message:
                    ErrorCodeMessages[
                    	(error as AxiosError)?.response?.status
                    ]
                    || (error as AxiosError)?.message
                    || (typeof error === 'object' ? JSON.stringify(error) : error),
				url: url ?? (error as AxiosError)?.config?.url,
			},
		];
		throw errors;
	}
}

export const handleErrorNew = (error: AxiosError | ThrownError): never => {
	if ((error as ThrownError).isThrownError) {
		throw (error as ThrownError).errors;
	} else if (
		(error as AxiosError).isAxiosError
        && (error as AxiosError).response
	) {
		const response: AxiosResponse = (error as AxiosError).response;
		throw response.data?.errors;
	} else {
		if (
			(error as AxiosError)?.response?.status
            === ErrorCodes.ServiceUnavailable
		) {
			window.location.reload();
		}
		const errors = [
			{
				code: (error as AxiosError)?.response?.status || 555,
				message:
                    ErrorCodeMessages[
                    	(error as AxiosError)?.response?.status
                    ]
                    || (error as AxiosError)?.message
                    || (typeof error === 'object' ? JSON.stringify(error) : error),
				url: (error as AxiosError)?.config?.url,
			},
		];
		throw errors;
	}
};
