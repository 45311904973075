import React, { FC, useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Testimonials.scss';
import { i18n } from '../../../../../symphony';
import { Padding } from '../../shared/Padding/Padding';
import { ReviewBackground } from '../../shared/icons';

type Props = {
	testimonialKeys: string[];
};

export const Testimonials: FC<Props> = ({ testimonialKeys }) => {
	const [items, setItems] = useState<{ key: string, width: string; }[]>([]);

	useEffect(() => {
		const items = testimonialKeys.map(k => {
			let hash = 0;
			for (let i = 0; i < k.length; i++) {
				const charCode = k.charCodeAt(i);
				hash = (hash << 5) - hash + charCode;
				hash |= 0;
			}
			const rangeSize = 20;
			const positiveInput = hash < 0 ? -hash : hash;
			const normalizedInput = positiveInput % (rangeSize + 1);
			return {
				key: k, width: `${70 + normalizedInput}%`
			};
		});
		setItems(items);
	}, [testimonialKeys]);

	useStyles(s);
	return <Padding className='wta-testimonials'>
		{items.map(k => (
			<div
				className='wta-testimonials-item'
				key={k.key}
				style={{ width: k.width }}
			>{i18n.t(k.key)}</div>
		))}
		<div className="wta-testimonials-background">
			<ReviewBackground />
		</div>
	</Padding>;
};