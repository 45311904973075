import React, { FC } from "react";
import { i18n } from "../../../../../symphony";
import { Padding } from "../../shared/Padding/Padding";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Heading.scss';

type Props = {
	locKey: string;
	level: number;
	style: React.CSSProperties;
};

export const Heading: FC<Props> = ({ locKey, level, style }) => {
	useStyles(s);
	switch (level) {
		case 1:
			return <h1
				className="wta-heading h1"
				style={style}
			>{i18n.t(locKey)}</h1>;
		case 2:
			return <h2
				className="wta-heading h2"
				style={style}
			>{i18n.t(locKey)}</h2>;
		case 3:
			return <h3
				className="wta-heading h3"
				style={style}
			>{i18n.t(locKey)}</h3>;
		case 4:
			return <h4
				className="wta-heading h4"
				style={style}
			>{i18n.t(locKey)}</h4>;
	}
};
