import React, { ChangeEvent, FC, useEffect, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './WTAEmailUpdatePage.scss';
import { Container, i18n, Trans } from "../../../../../symphony";
import { WebToAppService } from "../../../services/webtoapp.service";
import { Padding } from "../../shared/Padding/Padding";
import { WTAButton } from "../../shared/WTAButton/WTAButton";
import { SlIcon } from "@sololearnorg/icons";
import { LocalizedLink } from "../../../../../shared/public/LocalizedLink/LocalizedLink";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { WebToAppStore } from "../../../stores/webtoapp.store";
import { Heading } from "../../pageComponents/Heading/Heading";

type Props = {};

export const WTAEmailUpdatePage: FC<Props> = ({ }) => {
	useStyles(s);
	const [email, setEmail] = useState<{ email: string, isValid: boolean; }>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const {
		emailUpdateError,
		userEmail
	} = useContainerData('wta', WebToAppStore, ['emailUpdateError', 'userEmail']);

	const {
		footer,
		progressBar,
		emailUpdatePageHandler,
		toNextPage
	} = Container.take('wta', WebToAppService);

	useEffect(() => {
		footer.hide();
		progressBar.hide();
	}, []);

	useEffect(() => {
		if (emailUpdateError) {
			setLoading(false);
		}
	}, [emailUpdateError]);

	useEffect(() => {
		setIsEditing(!userEmail);
	}, [userEmail]);

	const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail({
			email: event.target.value,
			isValid: /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+)@(?:(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/.test(event.target.value)
		});
	};

	const onEditClick = () => {
		setIsEditing(true);

	};

	const onSubmit = () => {
		if (isEditing) {
			setLoading(true);
			emailUpdatePageHandler(email.email);
		} else {
			toNextPage();
		}
	};

	return <Padding className="wta-email-update">
		<div className="wta-email-update-logo">
			<SlIcon iconId="sololearn" />
		</div>
		<Heading
			locKey="wta.update-email.title"
			level={2}
			style={{
				paddingBottom: 0
			}}
		/>
		<div className="wta-email-update-form">
			<label
				htmlFor="wta-email-update"
				className="wta-email-update-label"
			>{i18n.t('wta.update-email.label')}</label>
			{isEditing ? <>
				<input
					type="email"
					name="wta-email-update"
					id="wta-email-update"
					placeholder={i18n.t('wta.update-email.input-placeholder')}
					className={`wta-email-update-input ${emailUpdateError ? 'error' : ''}`}
					disabled={loading}
					onChange={onEmailChange}
					defaultValue={userEmail || ''}
				/>
			</> : <div className="wta-email-update-existing-email">
				<span>{userEmail}</span>
				<div className="wta-email-update-edit" onClick={onEditClick}>
					<SlIcon iconId={'pencil'} />
				</div>
			</div>}
			{emailUpdateError && <caption className="wta-email-update-error">{i18n.t('wta.update-email.error.existing')}</caption>}
			<p className="wta-email-update-disclaimer">
				<Trans
					i18nKey="wta.update-email.caution"
					components={[
						<LocalizedLink
							to={'/Terms-of-Use'}
							relative="route"
							target="_blank"
						/>,
						<LocalizedLink
							to={'/Terms-of-Use'}
							relative="route"
							target="_blank"
						/>,
					]}
				/>
			</p>
		</div>

		<WTAButton
			onClick={onSubmit}
			background="purple"
			locKey="wta.update-email.cta"
			disabled={isEditing && !email?.isValid}
			loading={loading}
		/>

		<div className="wta-email-update-awards">
			<div className="wta-email-update-award">
				<SlIcon iconId="appleGenerativeAward" />
			</div>
			<div className="wta-email-update-award">
				<SlIcon iconId="googlePlayEditorsChoice" />
			</div>
		</div>
	</Padding>;
};