import { WTAPage, WTAPageType } from "./types/webtoapp.types";

export const WTAGettingStartedPage: WTAPage = {
	id: 239,
	type: WTAPageType.getStarted,
	title: "wta.page.239.title",
	content: [],
	style: {},
	pageOptions: []
};

export const WTAInitializationPage: WTAPage = {
	id: 0,
	type: WTAPageType.flowInitialization,
	title: '',
	content: [],
	style: {}
};