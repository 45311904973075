import React, { FC, useEffect, useState } from 'react';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { PaywallsStore } from '../../paywalls.store';
import { PaywallPage, PaywallPageTypes } from '../../../../api/public/subscription-api';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallPageDrawer.scss';
import { GenericPaywallPage } from '../GenericPaywallPage/GenericPaywallPage';

export const PaywallPageDrawer: FC = () => {
	useStyles(s);
	const {
		paywallData,
		paywallNavigation
	} = useContainerData('paywalls', PaywallsStore, [
		'paywallData',
		'paywallNavigation'
	]);
	const [page, setPage] = useState<PaywallPage>(null);

	useEffect(() => {
		setPage(paywallData.data.find(p => p.order.toString() === paywallNavigation.step));
	}, [paywallNavigation]);

	const pageComponent = (type: PaywallPageTypes) => {
		switch (type) {
			case PaywallPageTypes.generic:
				return <div className='transition-item'>
					<GenericPaywallPage page={page} />
				</div>;
		}
	};

	return <div className="drawer">
		<div className="drawer-wrapper">
			{page && <TransitionGroup component={null}>
				<CSSTransition
					key={page?.order}
					timeout={500}
					classNames="fade"
				>
					{pageComponent(page.type)}
				</CSSTransition>
			</TransitionGroup>}
		</div>
	</div >;
};