import React, { FC, useState } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './SingleChoiceList.scss';
import { i18n } from "../../../../../symphony";
import { WTAPageOption, WTASingleChoiceChangeHandler } from "../../../types/webtoapp.types";

type Props = {
	options: WTAPageOption[];
	selectedValue?: number;
	onChange?: WTASingleChoiceChangeHandler;
};

export const SingleChoiceList: FC<Props> = ({ options, selectedValue, onChange }) => {
	useStyles(s);
	const [selected, setSelected] = useState<number>(selectedValue || null);

	const onOptionSelect = (option: WTAPageOption) => {
		if (option.answerTypeId === selected) return;
		setSelected(option.answerTypeId);
		onChange && onChange(option);
	};

	return <div className="wta-list">
		{options.map(o => (<div
			onClick={() => { onOptionSelect(o); }}
			className={`wta-option ${selected === o.answerTypeId ? 'selected' : ''}`}
			key={o.value}
		>
			{i18n.t(o.label)}
		</div>))}
	</div>;
};